import React,{useState,useEffect} from 'react';
import {
	//BrowserRouter as Router,
	HashRouter as Router,
	Switch,
	Route,
	//Link,
	Redirect,
	// useParams,
	// useRouteMatch
} from "react-router-dom";
import Header from './components/header';
import Footer from './components/footer';
import HomePage from './container/home';
// import data from './data/footer';
import CompanyIntroduction from './container/companyIntroduction';
import WellSiteMonitoring from './container/wellSiteMonitoring';
import ServiceSupport from './container/serviceSupport';
import JoinUs from './container/joinUs';

import FieldInstruments from './container/fieldInstruments';
import PlatformManagement from './container/platformManagement';
import SiteMonitoring from './container/siteMonitoring';
import request from './utils/request';
import "./App.css";

import { Scrollbars } from 'react-custom-scrollbars';

function App() {

	const [data, setData] = useState([]);
	const [height, setHeight] = useState(200);

    function getData(){
        request('./json/footer.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

	useEffect(() => {
		getData();
		let height = document.documentElement.clientHeight-80;
		setHeight(height);
    },[])
	
	return (
		<div className="App">
			<Router>
				<Header />
				<Scrollbars thumbSize={100} style={{height}} autoHide={true}>
					<div style={{minHeight:'calc(100% - 100px)'}}>
						<Switch>
							<Redirect exact from="/" to={`${window.rootDir}/home`} />
							<Route exact path={`${window.rootDir}/home`} component={HomePage} />
							<Route exact path={`${window.rootDir}/wellSiteeMonitoring`} component={WellSiteMonitoring} />
							<Route exact path={`${window.rootDir}/serviceSupport`} component={ServiceSupport} />
							<Route exact path={`${window.rootDir}/companyIntroduction`} component={CompanyIntroduction} />
							<Route exact path={`${window.rootDir}/joinUs`} component={JoinUs} />
							<Route exact path={`${window.rootDir}/fieldInstruments`} component={FieldInstruments} />
							<Route exact path={`${window.rootDir}/platformManagement`} component={PlatformManagement} />
							<Route exact path={`${window.rootDir}/siteMonitoring`} component={SiteMonitoring} />
						</Switch>
					</div>
					<Footer data={data} />
				</Scrollbars>
			</Router>
		</div>
	);
}

export default App;
