import React,{useState,useEffect} from 'react';
import CarouselComponent from '../../components/carousel';
import Text from '../../components/text';
import Text2 from '../../components/text2';
import Content from '../../components/content';
import DyCard from '../../components/dyCard';
import TimeLine from '../../components/timeLine';
// import {data1,data2,data3,data4,data5} from '../../data/home';
import request from '../../utils/request';

import './style.css';

function HomePage(){
    const [data, setData] = useState({});

    function getData(){
        request('./json/home.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    return (
        <div>
           <CarouselComponent /> 
           <Text text="关于我们" />
           <Content data={data.data1 || []} />
           <Text text="我们的优势" />
           <Text2 text="提供从设计到交付的全程专业服务，为客户创造真正价值" />
           <DyCard data={data.data2 || []} />
           <Text text="产品与服务" />
           <DyCard data={data.data3 || []} style={{background:'rgba(25, 158, 216, 1)',padding:'60px 20px',borderRadius:'5px',color:'#fff'}} />
           <Text text="企业资质" />
           <TimeLine data={data.data4 || []} />
           <div className="home-pic">
               <img src={data.data5 || ''} />
            </div>
        </div>
    )

} 



export default  HomePage;