import React,{useState,useEffect} from 'react';
import Carousel from 'nuka-carousel';
import "./style.css";
// import data from '../../data/carousel';
import request from '../../utils/request';
export default function CarouselComponent(){
    const [data, setData] = useState([]);

    function getData(){
        request('./json/carousel.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }
    useEffect(() => {
        getData();
    },[])
    return(
        <div style={{height:"600px"}}>
            <Carousel autoplay={true} wrapAround={true} animation initialSlideHeight={600} speed={600}>
                {
                    data.map(item => {
                        return (
                            <div className="carousel-item">
                                <img src={item.img} />
                                <div className="c-tips" style={{top:item.top}}>
                                    <div className="title">{item.title}</div>
                                    <div style={{textIndent:'28px'}}>{item.text}</div>
                                    
                                </div>
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}