import React,{useState,useEffect} from 'react';
import data from '../../data/serviceSupport';
import Text2 from '../../components/text2';
import request from '../../utils/request';
import './style.css';
export default function ServiceSupport(){
    const [data, setData] = useState([]);

    function getData(){
        request('./json/serviceSupport.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    return(
        <div className='serviceSupport-box'>
            <div className="serviceSupport-box-pic"><img src={require('../../images/zhihci/1.jpg')} /></div>
            {
                data.map((item,idx) => {
                    return(
                        <div key={idx} style={{marginBottom:'10px',padding:'20px'}}>
                            <Text2 text={item.title} style={{justifyContent:'flex-start'}} />
                            {
                              item.des.map((m,i) => {
                                 return <div style={{textIndent:'52px'}} key={idx}>{m}</div>
                              })  
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}