 /* jshint esversion: 6 */ 
import React,{useState,useEffect} from 'react';
import Content from '../../components/content';
// import data from '../../data/companyIntroduction';
import request from '../../utils/request';
import "./style.css";

export default function CompanyIntroduction(){

    const [data, setData] = useState([]);

    function getData(){
        request('./json/companyIntroduction.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    return(
        <div>
            <div className="companyIntroduction-box-pic"><img src={require('../../images/jianjie/1.jpg')} /></div>
            <Content data={data}/>
        </div>
    )

} 