import React,{useState,useEffect} from 'react';
import Card2 from '../../components/card2';
// import data from '../../data/siteMonitoring';
import request from '../../utils/request';
export default function SiteMonitoring(){
    const [data, setData] = useState([]);

    function getData(){
        request('./json/siteMonitoring.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    return(
        <div>
            <Card2 data={data} />
        </div>
    )
}