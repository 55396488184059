import React,{useState,useEffect} from 'react';
import Card2 from '../../components/card2';
// import data from '../../data/fieldInstruments';
import request from '../../utils/request';
import config from '../../utils/config';
export default function FieldInstruments(){
    const [data, setData] = useState([]);

    function getData(){
        request('./json/fieldInstruments.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])
    
    return(
        <div style={{minHeight:'calc(100vh - 180px)'}}>
            <Card2 data={data} />
        </div>
    )
}