
import axios from'axios';
import {message} from 'antd';

export default function request(url,options){
    return new Promise((resolve,reject) => {
        axios(url, {
            headers:{
                'Content-Type':'application/json',
            },
            timeout:20000,
            // credentials:'include',
            // withCredentials:true,
           ...options
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                let dataSourse = response.data;
                resolve(dataSourse);
                
            }else{
                reject(response.message);
            }
        })
        .catch(error => {
            message.warning(error);
            console.log('连接失败',error);
          
        });
    }).catch(err => {
        message.warning(err);
    });  
};
