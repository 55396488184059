import React,{useState,useEffect} from 'react';
import './style.css';
import {withRouter} from "react-router-dom";
// import data from '../../data/menu';
import request from '../../utils/request';
import {Link} from "react-router-dom";

function Header(props){

    const [data, setData] = useState([]);

    function getData(){
        request('./json/menu.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    function menuTml(data){
        return data.map(item => {
            return <div key={item.key} className="item"><Link to={item.path}>{item.title}</Link></div>

        });
        
    }

    return(
        <div className="header-wrap">
            <div className="header-wrap-logo">
                <img width="100%" src={require('../../images/logo.png')} />
            </div>
            <div className="header-wrap-menu">
                {
                    data.map(item => {
                        let bol = item.path.indexOf(props.location.pathname) > -1;
                        return(
                            <div key={item.key} className="header-wrap-menu-item">
                                { item.children.length==0 && <Link to={item.path}><span style={{color:bol ? 'rgba(25, 158, 216, 1)' : '#666'}}>{item.title}</span></Link> || <span style={{color:bol ? 'rgba(25, 158, 216, 1)' : '#666'}}>{item.title}</span>}
                                {
                                    item.children.length>0 && (
                                       <div className="header-wrap-menu-item-children">
                                           {menuTml(item.children)}
                                       </div>
                                    )
                                }
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default withRouter(Header);