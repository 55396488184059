import React from 'react';
import './style.css';

export default function Card2({data=[]}){
    return(
        <div className="card2-wrap">
            {
                data.map((item,idx) => {
                    return (
                        <div className="card2-wrap-item" key={idx}>
                            
                            <img alt="" src={item.img} />
                            <div className="des">
                                {
                                item.test.map((m,i) => {
                                        return <div key={i}>{m}</div>
                                }) 
                                }
                            </div>
                            <div className="title">{item.title}</div>
                              
                        </div>
                    )
                })
            }
        </div>
    )
}