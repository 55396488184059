 /* jshint esversion: 6 */ 
import React from 'react';
import {withRouter} from "react-router-dom"
import './style.css';

function DyCard(props){
    function goPage(p){
        props.history.push({
            pathname:p
        });
    }
    return(
        <div className="dyCard-box">
            {
                props.data.map((item,idx)=>{
                    return(
                        <div className="col" key={idx} style={props.style} onClick={() => {goPage(item.path)}}>
                            <div><img src={item.img}/></div>
                            <div className="title">{item.title}</div>
                            <div>{item.text}</div>
                        </div>
                    ) 
                })
            }
        </div>
    )
}

export default withRouter(DyCard);