import React,{useState,useEffect} from 'react';
import data from '../../data/join';
import Text2 from '../../components/text2';
import request from '../../utils/request';
import './style.css';
export default function JoinUs(){
    const [data, setData] = useState([]);

    function getData(){
        request('./json/join.json',{
            method:'get'
        }).then(data => {
            setData(data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    return(
        <div className='joinus-box'>
            <div className="joinus-box-pic"><img src={require('../../images/join/1.jpg')} /></div>
            <Text2 text="人才理念：坚持以人为本，提供施展平台，激发工作热情，挖掘个人潜质，实现个人价值。"/>
            <div style={{padding:'30px 20px',fontSize:'2rem'}}>随着公司业务的发展，现长期招聘以下英才：</div>
            {
                data.map((item,idx) => {
                    return(
                        <div key={idx} style={{marginBottom:'20px',padding:'30px'}}>
                            {item.title && <Text2 text={item.title} style={{justifyContent:'start'}} />}
                            {(item.title &&  idx!= (data.length-2)) && <div style={{lineHeight:'50px',paddingLeft:'10px',textIndent:'30px'}}>岗位要求：</div>}
                            
                            {
                              item.des.map((m,i) => {
                                 return <div style={{textIndent:'45px'}} key={idx}>{m}</div>
                              })  
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}