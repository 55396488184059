import React from 'react';
import './style.css';

export default function TimeLine({data=[],style={}}){
    return(
        <div className="timeLine-box">
            <div className="line"></div>
            {
                data.map((im,idx) => {
                    if(idx % 2 == 0){
                        return(
                            <div className="box-wrap">
                                <div className="box" key={im.key} style={{textAlign:'right'}}>
                                    {im.text}
                                    <div className="cricl" style={{left:'calc(100% - 8px)'}}></div>
                                </div>
                            </div>
                            
                        )
                    }else{
                        return(
                            <div className="box-wrap">
                                <div className="box" key={im.key} style={{left:'50%'}}>
                                    {im.text}
                                    <div className="cricl"></div>
                                </div>
                            </div>
                            
                        )
                    }
                    
                })
            }
            
        </div>
    )
}