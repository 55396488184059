import React from 'react';
import './style.css';

export default function Footer({data=[]}){
    return(
        <div className="footer">
            {
                data.map((item,idx) => {
                    return <div key={idx} className="col">
                        {
                            item.map((m,i) => {
                                if(!m.src){
                                    return(
                                        <span key={i}>{m.img && <img src={m.img} />}{m.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    )
                                }else{
                                    return(
                                        <a style={{color:'#fff'}} target="_blank" key={i} href={m.src}>{m.img && <img src={m.img} style={{position:'relative',top:'5px'}} />}{m.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                    )
                                }
                            })
                        }

                    </div>
                })
            }
            
        </div>
    )
}