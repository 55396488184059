import React from 'react';
import './style.css';

export default function Content({data=[]}){
    return(
        <div className="content-box">
            {
                data.map((item,idx) => {
                    return (
                        <div className="item">{item}</div>
                    )
                })
            }
           
        </div>
    )
}